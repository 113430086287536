/* Tüm sayfa için varsayılan ayarlar */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  /* Root container için flex ayarları */
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Footer'ı sayfanın en altına itmek için */
  footer {
    margin-top: auto;
  }

  /* Body*/
  body {
    background-color: #15161D;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
  /* #1E1F29  açık */
  /* #15161D koyu*/